import React from "react";
import logo from "../logo.png";
import "./App.css";
import {useAuth} from "&/AuthContext";

const Views = React.lazy(() => import("./views"))

function App() {
    useAuth();
    
    return (
        <div className="App">
            <React.Suspense fallback={null}>
                <Views logo={logo} />
            </React.Suspense>
        </div>
    );
}

export default App;