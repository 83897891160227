import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/100-italic.css";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/300-italic.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/400-italic.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/500-italic.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto/700-italic.css";
import "@fontsource/roboto/900.css";
import "@fontsource/roboto/900-italic.css";
import {createTheme, ThemeProvider} from "@mui/material/styles";
import {AuthProvider} from "./AuthContext";
import App from "./app/App";

const theme = createTheme({
    typography: {
        fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"].join(", "),
        button: {fontWeight: "normal"},
        h6: {fontWeight: "normal"}
    }
});

ReactDOM.render(
    <React.StrictMode>
        <AuthProvider>
            <ThemeProvider theme={theme}>
                <App/>
            </ThemeProvider>
        </AuthProvider>
    </React.StrictMode>, 
    document.getElementById("root")
);