export const AMPLIFY_CONFIG = {
    Auth: {
        Cognito: {
            region: process.env.REACT_APP_COGNITO_REGION,
            userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
            userPoolClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
            loginWith: {
                oauth: {
                    domain: process.env.REACT_APP_COGNITO_OAUTH_DOMAIN,
                    scopes: ["phone", "email", "profile", "openid", "aws.cognito.signin.user.admin"],
                    redirectSignIn: [process.env.REACT_APP_COGNITO_REDIRECT_URL],
                    redirectSignOut: [process.env.REACT_APP_COGNITO_REDIRECT_URL],
                    responseType: "code"
                }
            }
        }
    }
};